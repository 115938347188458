import fetch from 'isomorphic-fetch';
import { ApolloClient, HttpLink, InMemoryCache, from } from '@apollo/client';
import { onError } from "@apollo/client/link/error";

const httpLink = new HttpLink({
    uri: `${process.env.GATSBY_WPGRAPHQL_URL}`,
    fetch,
});
  
const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
            console.log(message);
            console.log(locations);
            console.log(path);
        });
  
    if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([errorLink, httpLink]),
});

export default client;